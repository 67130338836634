/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.2.4/dist/jquery.min.js
 * - /npm/jquery-validation@1.21.0/dist/jquery.validate.min.js
 * - /npm/jquery-validation@1.21.0/dist/additional-methods.min.js
 * - /npm/jquery-serializejson@2.9.0/jquery.serializejson.min.js
 * - /npm/js-cookie@3.0.5/dist/js.cookie.min.js
 * - /npm/owl.carousel@2.2.0/dist/owl.carousel.min.js
 * - /npm/dayjs@1.11.7/dayjs.min.js
 * - /npm/dayjs@1.11.9/plugin/isBetween.js
 * - /npm/selectric@1.13.0/public/jquery.selectric.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
